import React from 'react';

class FourOFour extends React.PureComponent {
  render() {
    return (
      <div>
        <h1>Page not found</h1>
      </div>
    );
  }
}

export default FourOFour;
